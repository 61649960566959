import React from "react";
import LazyImage from "../hooks/LazyImage";
import getAllImages from "../static/imageUtils";
import Tobii from "@midzer/tobii";
import "@midzer/tobii/dist/tobii.min.css";
import "@midzer/tobii/dist/tobii.min.js";
import "../styles/App.css";

export default function AboutUsPage() {
  const tobiiRef = React.useRef(null);
  const { web_content, founders, teams } = getAllImages;

  React.useEffect(() => {
    const checkAndInitTobii = () => {
      const lightboxItems = document.querySelectorAll("[data-tobii]");
      if (lightboxItems.length > 0 && !tobiiRef.current) {
        tobiiRef.current = new Tobii({
          captions: false,
          counter: true,
          nav: "auto",
          zoom: true,
          draggable: true,
          keyboard: true,
          theme: "dark",
        });
      }
    };

    const timeout = setTimeout(checkAndInitTobii, 100);

    return () => {
      clearTimeout(timeout);
      if (tobiiRef.current) {
        tobiiRef.current.destroy();
        tobiiRef.current = null;
      }
    };
  }, []);

  return (
    <>
      <section className="py-10 relative xl:mr-0 lg:mr-5 mr-0">
        <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
          <div className="w-full justify-start items-center xl:gap-12 gap-10 grid lg:grid-cols-2 grid-cols-1">
            <div className="w-full flex-col justify-center lg:items-start items-center gap-5 inline-flex">
              <div className="mt-10 lg:mt-0">
                <span className="block mb-4 text-lg font-semibold text-primary">
                  What We Do
                </span>
                <h2 className="mb-5 text-3xl font-bold text-dark dark:text-white sm:text-[40px]/[48px]">
                  We work with IOT & provide the softwere to User with their
                  Solve daily life problem
                </h2>
                <p className="mb-5 text-md/8 text-gray-700 dark:text-dark-6">
                  In our pursuit of excellence in the realm of Internet of
                  Things (IoT), we specialize in developing and delivering
                  cutting-edge software solutions that directly address and
                  resolve the everyday challenges faced by individuals.
                </p>
                <p className="mb-8 text-md/8 text-gray-700 dark:text-dark-6">
                  {" "}
                  Our mission revolves around providing users with tailored
                  software applications designed to streamline and improve their
                  daily lives.
                </p>
                <p className="mb-8 text-md/8 text-gray-700 dark:text-dark-6">
                  By leveraging the transformative capabilities of IoT
                  technology, we aim to deliver innovative solutions that not
                  only enhance user experiences but also contribute
                  significantly to solving prevalent issues encountered in
                  various aspects of daily life.{" "}
                </p>
                <p className="mb-8 text-md/8 text-gray-700 dark:text-dark-6">
                  Through our commitment to user-centric design and
                  technological innovation, we aspire to make a meaningful and
                  positive impact on the lives of our users, ultimately making
                  their routines more efficient, connected, and seamlessly
                  integrated with smart solutions.
                </p>
                <p className="mb-8 text-md/8 text-gray-700 dark:text-dark-6">
                  A domain name is one of the first steps to establishing your
                  brand. Secure a consistent brand image with a domain name that
                  matches your business.
                </p>
              </div>
              <button className="sm:w-fit w-full group px-3.5 py-2 bg-indigo-50 hover:bg-indigo-100 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] transition-all duration-700 ease-in-out justify-center items-center flex">
                <span className="px-1.5 text-indigo-600 text-sm font-medium leading-6 group-hover:-translate-x-0.5 transition-all duration-700 ease-in-out">
                  Explore
                </span>
                <svg
                  className="group-hover:translate-x-0.5 transition-all duration-700 ease-in-out"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M6.75265 4.49658L11.2528 8.99677L6.75 13.4996"
                    stroke="#4F46E5"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="w-full lg:justify-start justify-center items-start flex">
              <div className="sm:bg-gray-100 rounded-3xl sm:border border-gray-200 relative">
                <LazyImage
                  className="sm:mt-5 sm:ml-5 w-full h-full rounded-3xl object-cover"
                  src={web_content["team_meeting.png"]}
                  alt="about Us"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative isolate flex flex-col items-center justify-between mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-semibold tracking-tight ">
            Our Founder
          </h2>
        </div>
        <div className="py-8 flex flex-wrap lg:flex-nowrap gap-4 border-b border-gray-200">
          <div className="w-full md:w-64 mb-6 md:mb-0 flex-shrink-0 flex flex-col items-center">
            <LazyImage
              src={founders["neeta_sinha.jpg"]}
              className="w-full rounded-3xl border-2 border-gray-200"
              alt="Neeta Sinha"
            />
          </div>
          <div className="flex-1 flex flex-col justify-center">
            <blockquote className="relative">
              <svg
                className="absolute top-0 start-0 transform -translate-x-6 -translate-y-8 size-16 text-gray-200/90 z-20"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z"
                  fill="currentColor"
                />
              </svg>{" "}
              <p className="relative text-md/8 text-gray-700 dark:text-dark-6 z-40 text-justify">
                "Growth and comfort do not coexist. This profound quote reminds
                us that life's pursuits may not always grant us every desire.
                However, it emphasizes the importance of prioritizing and
                attaining what truly holds value. It encourages thoughtful
                reflection on personal values and priorities, suggesting that by
                aligning our efforts with what genuinely matters, we can
                cultivate a meaningful and satisfying existence."
              </p>
              <p className="leading-relaxed text-md/8 text-gray-700 mt-2">
                — Neeta Sinha (Founder)
              </p>
            </blockquote>
          </div>
        </div>

        <div className="py-8 flex flex-wrap lg:flex-nowrap gap-4">
          <div className="w-full md:w-64 mb-6 md:mb-0 flex-shrink-0 flex flex-col items-center">
            <LazyImage
              src={founders["srishti.jpg"]}
              className="w-full rounded-3xl shadow-md border-2 border-gray-200"
              alt="srishti founder"
            />
          </div>
          <div className="flex-1 flex flex-col gap-4 justify-center">
            <blockquote className="relative">
              <svg
                className="absolute top-0 start-0 transform -translate-x-6 -translate-y-8 size-16 text-gray-200/90 z-20"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z"
                  fill="currentColor"
                />
              </svg>{" "}
              <p className="relative text-md/8 text-gray-700 dark:text-dark-6 z-40 px-2 text-justify">
                "Keep going forward because success will come. This quote
                encourages perseverance and optimism, emphasizing that
                continuous effort and a positive outlook lead to success. It
                inspires individuals to remain resilient in the face of
                challenges, conveying the belief that by persistently moving
                forward, one will ultimately achieve success in their
                endeavors."
              </p>
              <p className="leading-relaxed text-md/8 text-gray-700 mt-2">
                — Srishti (Founder)
              </p>
            </blockquote>
          </div>
        </div>
      </section>

      <section className="relative isolate flex flex-col items-center justify-between mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="w-96 flex justify-center items-center">
          <a
            href={web_content["certified.jpg"]}
            data-tobii
            key={1}
            data-tobii-group="gallery"
            className="lightbox cursor-pointer"
          >
            <LazyImage
              as="span"
              className="w-full w-full object-fit"
              src={web_content["certified.jpg"]}
              alt="sertificate"
            />
          </a>
        </div>
      </section>

      <section
        className="mb-[3rem] py-10 relative isolate flex items-center mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-gray-50"
        style={{ marginTop: "3rem", marginBottom: "3rem" }}
      >
        <div className="w-full px-2 sm:px-4 lg:px-6">
          <div className="text-center flex flex-col flex-1 gap-10 lg:gap-0 lg:flex-row lg:justify-between gap-4">
            <div className="w-full lg:w-1/4 border-b pb-10 lg:border-b-0 lg:pb-0 lg:border-r border-gray-100 cursor-pointer ">
              <div className="font-manrope font-bold text-6xl text-indigo-600 mb-2 text-center ">
                405+
              </div>
              <span className="text-xl text-gray-500 text-center block ">
                Booking Order
              </span>
            </div>
            <div className="w-full lg:w-1/4 border-b pb-10 lg:border-b-0 lg:pb-0 lg:border-r border-gray-100 cursor-pointer">
              <div className="font-manrope font-bold text-6xl text-indigo-600 text-center ">
                295+
              </div>
              <span className="text-xl text-gray-500 text-center block ">
                Booking Completed
              </span>
            </div>
            <div className="w-full lg:w-1/4 border-b pb-10 lg:border-b-0 lg:pb-0 lg:border-r border-gray-100 cursor-pointer ">
              <div className="font-manrope font-bold text-6xl text-indigo-600 text-center ">
                3
              </div>
              <span className="text-xl text-gray-500 text-center block ">
                Available States
              </span>
            </div>
            <div className="w-full lg:w-1/4  cursor-pointer">
              <div className="font-manrope font-bold text-6xl text-indigo-600  text-center ">
                2
              </div>
              <span className="text-xl text-gray-500 text-center block ">
                Award Winner
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="relative isolate flex flex-col justify-between mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="max-w-5xl mx-auto mb-10 lg:mb-14">
          <h2 className="text-4xl font-bold md:text-4xl md:leading-tight">
            Why Choose Us
          </h2>
        </div>
        <div className="max-w-7xl mx-auto divide-y divide-gray-200">
          <div className="py-8 first:pt-0 last:pb-0">
            <div className="flex gap-x-3">
              <svg
                className="shrink-0 mt-1 size-6 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                <path d="M12 17h.01" />
              </svg>

              <div className="grow">
                <h3 className="md:text-lg mb-3 font-semibold text-gray-800 text-4xl">
                  Our Mission?
                </h3>
                <p className="mt-1 text-gray-500 text-md/9 text-justify">
                  At{" "}
                  <span className="bold text-indigo-600">Sarva Suvidhaen</span>{" "}
                  , our mission is to drive progress through continuous
                  innovations in software development since our inception in
                  2021. Fueled by a commitment to excellence, we specialize in
                  crafting Python-based analytical tools and real-time
                  monitoring applications that serve as the cornerstones of our
                  technological endeavors. Our core strength lies in providing
                  cutting-edge solutions by harnessing the power of IoT-enabled
                  frameworks and machine learning modeling. We are dedicated to
                  empowering businesses and individuals by delivering
                  sophisticated software solutions that not only meet but exceed
                  expectations, thus contributing to the advancement and
                  efficiency of diverse sectors. With a focus on technological
                  prowess and a passion for creating impactful solutions, our
                  mission is to be at the forefront of transformative
                  advancements, shaping the future through innovation and
                  excellence in software development.
                </p>
              </div>
            </div>
          </div>

          <div className="py-8 first:pt-0 last:pb-0">
            <div className="flex gap-x-5">
              <svg
                className="shrink-0 mt-1 size-6 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                <path d="M12 17h.01" />
              </svg>

              <div className="grow">
                <h3 className="md:text-lg font-semibold text-gray-800">
                  Our Vision?
                </h3>
                <p className="mt-1 text-gray-500">
                  At{" "}
                  <span className="bold text-indigo-600">Sarva Suvidhaen</span>{" "}
                  vision is to be a trailblazer in the realm of software
                  development, continuously propelling progress through
                  innovative solutions since our establishment in 2021.
                  Envisioning a future where technology seamlessly integrates
                  with daily life, we aspire to lead with Python-based
                  analytical tools and real-time monitoring applications as our
                  primary catalysts for positive change. We see our company as a
                  beacon of technological excellence, leveraging the power of
                  IoT-enabled frameworks and machine learning modeling to
                  provide visionary solutions that redefine efficiency,
                  connectivity, and problem-solving across diverse domains. Our
                  commitment extends beyond the present, foreseeing a landscape
                  where our cutting-edge software solutions not only meet
                  current needs but also anticipate and address the evolving
                  challenges of tomorrow, ultimately fostering a global impact
                  and contributing to the advancement of society through the
                  transformative potential of technology.
                </p>
              </div>
            </div>
          </div>

          <div className="py-8 first:pt-0 last:pb-0">
            <div className="flex gap-x-5">
              <svg
                className="shrink-0 mt-1 size-6 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                <path d="M12 17h.01" />
              </svg>

              <div className="grow">
                <h3 className="md:text-lg font-semibold text-gray-800">
                  Our Approach?
                </h3>
                <p className="mt-1 text-gray-500">
                  At{" "}
                  <span className="bold text-indigo-600">Sarva Suvidhaen</span>,
                  our approach is defined by a relentless commitment to fueling
                  progress through continuous innovation in software development
                  since our establishment in 2021. We distinguish ourselves
                  through the strategic utilization of Python-based analytical
                  tools and real-time monitoring applications, considering them
                  as the pivotal keys to unlocking technological advancements.
                  Our approach is further fortified by leveraging the power of
                  IoT-enabled frameworks and machine learning modeling,
                  positioning them as integral components that form the backbone
                  of our solutions. We prioritize staying at the forefront of
                  technological trends, ensuring that our software not only
                  meets the current demands of the industry but also anticipates
                  future needs. By embracing a proactive and forward-thinking
                  stance, we aim to provide our clients and users with solutions
                  that not only address their immediate challenges but also
                  empower them to thrive in an ever-evolving technological
                  landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative isolate flex flex-col justify-between mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14 mt-4">
          <h2 className="text-2xl font-bold md:text-4xl md:leading-tight">
            Our team
          </h2>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-8 md:gap-12">
          <div className="text-center">
            <LazyImage
              className="rounded-xl sm:size-48 aspect-square lg:size-60 mx-auto object-cover object-top"
              src={teams["Abhishek_Raushan.jpg"]}
              alt="Avatar"
            />
            <div className="mt-2 sm:mt-4">
              <h3 className="text-sm font-medium text-gray-800 sm:text-base lg:text-lg">
                Abhishek Raushan
              </h3>
              <p className="text-xs text-gray-600 sm:text-sm lg:text-base">
                UI/UX Designer
              </p>
            </div>
          </div>

          <div className="text-center">
            <LazyImage
              className="rounded-xl sm:size-48 aspect-square lg:size-60 mx-auto object-cover object-top"
              src={teams["harsh_mishra.png"]}
              alt="Avatar"
            />
            <div className="mt-2 sm:mt-4">
              <h3 className="text-sm font-medium text-gray-800 sm:text-base lg:text-lg">
                Harsh mishra
              </h3>
              <p className="text-xs text-gray-600 sm:text-sm lg:text-base">
                Back-end Developer
              </p>
            </div>
          </div>

          <div className="text-center">
            <LazyImage
              className="rounded-xl sm:size-48 aspect-square lg:size-60 mx-auto object-cover object-top"
              src={teams["mayank_sing_tomar.jpeg"]}
              alt="Avatar"
            />
            <div className="mt-2 sm:mt-4">
              <h3 className="text-sm font-medium text-gray-800 sm:text-base lg:text-lg">
                Mayank sing tomar
              </h3>
              <p className="text-xs text-gray-600 sm:text-sm lg:text-base">
                FullStack Dev
              </p>
            </div>
          </div>

          <div className="text-center">
            <LazyImage
              className="rounded-xl sm:size-48 lg:size-60 mx-auto aspect-square"
              src={teams["rutval.jpg"]}
              alt="Avatar"
            />
            <div className="mt-2 sm:mt-4">
              <h3 className="text-sm font-medium text-gray-800 sm:text-base lg:text-lg">
                Rutval
              </h3>
              <p className="text-xs text-gray-600 sm:text-sm lg:text-base">
                FullStack Dev
              </p>
            </div>
          </div>
        </div>
      </section>





      
    </>
  );
}
