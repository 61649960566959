import { useState, Fragment, useEffect } from "react";
import { Checkbox, Field, Label } from "@headlessui/react";
import * as Icons from "lucide-react";
import clsx from "clsx";
import emailjs from "emailjs-com";

export default function ContactUsPage() {
  const [enabled, setEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    company: "",
    message: "",
    country: "India",
  });

  const [isFormEmpty, setIsFormEmpty] = useState(true);

  useEffect(() => {
    setIsFormEmpty(
      Object.values(formData).some((value) => value.trim() === "")
    );
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => {
      if (name === "phoneNumber") {
        const sanitizedValue = value.replace(/\D/g, "").slice(0, 10);
        return { ...prevState, [name]: sanitizedValue };
      }
      return { ...prevState, [name]: value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!enabled || isFormEmpty) {
      return;
    }
    setIsSubmitting(true);

    // EmailJS configuration
    const serviceId = "service_x3y9pmc";
    const templateId = "template_g9u7tp9";
    const userId = "YBPHtMkiDsFRGpeN4";

    // Prepare the template parameters with formData
    const templateParams = {
      from_name: `${formData.firstName} ${formData.lastName}`,
      to_name: "Sarva Suvidhaen",
      email: formData.email,
      phone: formData.phoneNumber,
      company: formData.company,
      message: formData.message,
    };

    // Send email using EmailJS
    emailjs.send(serviceId, templateId, templateParams, userId).then(
      (response) => {
        console.log("Email sent successfully!", response.status, response.text);
        // alert('Email sent successfully!');
        setIsSubmitted(true);
        setIsSubmitting(false);
      },
      (error) => {
        console.error("Error sending email:", error);
        alert("Error sending email: " + error);
        setIsSubmitting(false);
        setIsSubmitted(false);
      }
    );
  };

  // const countryCodes = {
  //   India: "+91",
  //   // Add more countries here if needed
  // };

  // // Function to handle country selection change
  // const handleCountryChange = (e) => {
  //   const selectedCountry = e.target.value;
  //   setFormData({
  //     ...formData,
  //     country: selectedCountry,
  //     phoneNumber: countryCodes[selectedCountry] || "",
  //   });
  // };

  return (
    <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-pink-300 to-indigo-300 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          aria-hidden="true"
        ></div>
      </div>
      {isSubmitted ? (
        <>
          <div className="mx-auto max-w-md p-6 bg-green-100 border border-green-400 rounded-md shadow-md text-center">
            <Icons.CheckCircle className="mx-auto h-10 w-10 text-green-600" />
            <h2 className="text-2xl font-semibold text-green-800 mt-4">
              Submission Successful!
            </h2>
            <p className="text-green-700 mt-2">
              Thank you for contacting us, {formData.firstName}. We will get
              back to you soon.
            </p>
            <button
              className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-500"
              onClick={() => setIsSubmitted(false)}
            >
              Submit Another Response
            </button>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-4xl font-semibold tracking-tight text-balance text-gray-900 sm:text-5xl">
              Contact With Sarva Suvidhaen
            </h2>
          </div>
          <form
            onSubmit={handleSubmit}
            className="mx-auto mt-16 max-w-xl sm:mt-20"
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm/6 font-semibold text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2.5">
                  <input
                    id="first-name"
                    name="firstName"
                    type="text"
                    autoComplete="given-name"
                    className="block w-full border border-gray-100 rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                    onChange={handleChange}
                    value={formData.firstName}
                    required
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm/6 font-semibold text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2.5">
                  <input
                    id="last-name"
                    name="lastName"
                    type="text"
                    autoComplete="family-name"
                    className="block w-full border border-gray-100  rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                    onChange={handleChange}
                    value={formData.lastName}
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm/6 font-semibold text-gray-900"
                >
                  Company
                </label>
                <div className="mt-2.5">
                  <input
                    id="company"
                    name="company"
                    type="text"
                    autoComplete="organization"
                    className="block w-full border border-gray-100  rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                    onChange={handleChange}
                    value={formData.company}
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm/6 font-semibold text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full border border-gray-100  rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                    onChange={handleChange}
                    value={formData.email}
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="phone-number"
                  className="block text-sm/6 font-semibold text-gray-900"
                >
                  Phone number
                </label>
                <div className="mt-2.5">
                  <div className="flex rounded-md bg-white outline-1 -outline-offset-1 outline-gray-300 has-[input:focus-within]:outline-2 has-[input:focus-within]:-outline-offset-2 has-[input:focus-within]:outline-indigo-600">
                    <div className="grid shrink-0 grid-cols-1 focus-within:relative">
                      <select
                        id="country"
                        name="country"
                        autoComplete="country"
                        aria-label="Country"
                        className="col-start-1 border border-gray-100  row-start-1 w-full appearance-none rounded-md py-2 pr-7 pl-3.5 text-base text-gray-500 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                      >
                        <option>IN</option>
                      </select>
                      <Icons.ChevronDown
                        aria-hidden="true"
                        className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                      />
                    </div>
                    <input
                      id="phone-number"
                      name="phoneNumber"
                      type="tel"
                      placeholder="+91"
                      className="block min-w-0 grow border border-gray-100  py-1.5 pr-3 pl-1 text-base text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm/6"
                      onChange={handleChange}
                      value={formData.phoneNumber}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm/6 font-semibold text-gray-900"
                >
                  Message
                </label>
                <div className="mt-2.5">
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full border border-gray-100  rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                    onChange={handleChange}
                    value={formData.message}
                    required
                  />
                </div>
              </div>
              <Field className="flex gap-x-4 sm:col-span-2">
                <div className="flex h-6 items-center ">
                  <Checkbox
                    checked={enabled}
                    onChange={() => setEnabled(!enabled)}
                    as={Fragment}
                  >
                    {({ checked, disabled }) => (
                      <span
                        className={clsx(
                          "block size-4 rounded border",
                          !checked && "bg-white",
                          checked && !disabled && "bg-blue-500",
                          checked && disabled && "bg-gray-500",
                          disabled && "cursor-not-allowed opacity-50"
                        )}
                      >
                        <svg
                          className={clsx(
                            "stroke-white",
                            checked ? "opacity-100" : "opacity-0"
                          )}
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            d="M3 8L6 11L11 3.5"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    )}
                  </Checkbox>
                </div>
                <Label className="text-sm/6 text-gray-600">
                  By selecting this, you agree to our{" "}
                  <a href="#" className="font-semibold text-indigo-600">
                    privacy&nbsp;policy
                  </a>
                  .
                </Label>
              </Field>
            </div>
            <div className="mt-10">
              <button
                type="submit"
                className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={!enabled || isSubmitting || isFormEmpty}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}
