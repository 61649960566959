import React from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import * as Icons from "lucide-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Button } from "@headlessui/react";
import clsx from "clsx";
import getAllImages from "../static/imageUtils";
import LazyImage from "../hooks/LazyImage";

const Homepage = () => {
  const navigate = useNavigate();
  const { web_content } = getAllImages;

  return (
    <>
      <HeroSection />
      <CustomersSection />
      <WhoWeAreSection />
      <FeaturesSection />
      <StatsSection />
      <OurPartners />
      <ClientSection />
    </>
  );
};

export default Homepage;

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <section className="relative isolate flex items-center justify-between mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-pink-300 to-indigo-300 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          aria-hidden="true"
        ></div>
      </div>
      <div className="z-99 mx-auto max-w-2xl py-5">
        <div className="flex justify-center my-4">
          <mark
            className="inline-block px-3 py-1 rounded-md border-b-2 border-indigo-600 bg-indigo-50 text-indigo-600 font-bold tracking-tight text-sm sm:text-base md:text-lg lg:text-xl"
            style={{ fontFamily: "Josefin Sans, sans-serif" }}
          >
            <span className="relative z-10">Efficiency through Technology</span>
          </mark>
        </div>
        <div className="text-center">
          <h1
            className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold tracking-tight text-gray-900"
            aria-level="1"
          >
            Accelerate your Growth with our End-to-End Automation Solutions
          </h1>

          <p
            className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl font-medium text-gray-500"
            role="article"
          >
            We build process automation with seamless integration for optimizing
            performance. Build with us to realize your full organizational
            potential.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:gap-x-6">
              <Button
                aria-label="Sign Up For Free"
                as={Fragment}
                title="Sign Up For Free"
              >
                {({ hover, active }) => (
                  <button
                    className={clsx(
                      "rounded-lg  border-2 border-indigo-600 px-4 py-2 capitalize font-semibold text-white shadow-xs",
                      "bg-indigo-600 ocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                      hover ? "hover:bg-indigo-500" : "",
                      active ? "active:bg-indigo-700" : ""
                    )}
                    onClick={() => navigate("/signup")}
                  >
                    <span className="sr-only">Sign Up For Free</span>
                    Sign Up For Free
                  </button>
                )}
              </Button>

              <Button
                aria-label="Book a Demo"
                title="Book a Demo"
                as={Fragment}
              >
                {({ hover, active }) => (
                  <button
                    className={clsx(
                      "rounded-lg  px-4 py-2 capitalize font-semibold shadow-xs border-2 border-indigo-600 text-indigo-600 ocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                      hover ? "hover:bg-indigo-600 hover:text-white " : "",
                      active ? "active:bg-indigo-700" : ""
                    )}
                    onClick={() => navigate("/contact-us")}
                  >
                    <span className="sr-only">Book a Demo</span>
                    Book a Demo
                  </button>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const CustomersSection = () => {
  const { web_content } = getAllImages;
  return (
    <section className="relative isolate flex items-center justify-center mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div className="mx-auto max-w-7xl px-6 py-[20px] lg:px-8">
        <h2 className="mt-2 text-center text-4xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-5xl">
          Our Customers
        </h2>
        <div className="relative my-4 gap-6 flex flex-wrap flex-row  items-center justify-center bg-clip-border rounded-xl text-gray-700  rounded-xl text-gray-700 ">
          <div className="p-6 w-[200px] flex items-center justify-center">
            <LazyImage
              src={web_content["indainR2logo.png"]}
              alt="indainR2logo"
              width="158"
              height="158"
              className="bg-transparent mix-blend-color aspect-square"
            />
          </div>
          <div className="p-6 w-[200px] flex items-center justify-center">
            <LazyImage
              src={web_content["ECR_LOGO.webp"]}
              alt="partnerR4logo"
              width="158"
              height="158"
              className="bg-transparent mix-blend-color aspect-square"
            />
          </div>
          <div className="p-6 w-[200px] flex items-center justify-center aspect-square">
            <LazyImage
              src={web_content["indianRlogo.jpg"]}
              alt="indianRlogo"
              width="158"
              height="158"
              className="bg-transparent mix-blend-color aspect-square"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
const FeaturesSection = () => {
  const { web_content } = getAllImages;

  return (
    <section className="relative isolate flex items-center mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="py-10 w-full">
        <div className="mx-auto max-w-3xl px-3 lg:max-w-7xl lg:px-8">
          <h2
            className="mx-auto mt-2 max-w-lg text-center text-4xl font-semibold tracking-tight text-balance text-gray-950 sm:text-5xl capitalize"
            id="features-heading"
          >
            Features We Provide
          </h2>
          <div
            className="mt-10 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
            role="list"
            aria-labelledby="features-heading"
          >
            <div
              className="lg:row-span-2 shadow-md shadow-gray-50/50 border-2 transparent hover:border-indigo-600 p-4 rounded-xl"
              role="listitem"
              onMouseEnter={(e) => {
                e.currentTarget
                  .querySelector("h3")
                  .classList.add("text-indigo-600");
              }}
              onMouseLeave={(e) => {
                e.currentTarget
                  .querySelector("h3")
                  .classList.remove("text-indigo-600");
              }}
            >
              <div
                className="flex h-full flex-col items-center justify-between"
                aria-labelledby="feature-analytics-heading"
              >
                <div>
                  <div className="flex items-center gap-2">
                    <Icons.Workflow className="h-5 w-6" aria-hidden="true" />
                    <h3
                      className="text-lg font-medium text-gray-950 hover:text-indigo-600 cursor-pointer"
                      id="feature-analytics-heading"
                    >
                      Analytics and AI
                    </h3>
                  </div>
                  <p
                    className="mt-2 text-start max-w-lg text-sm text-gray-600 max-lg:text-center  font-medium"
                    style={{ fontFamily: "Josefin Sans, sans-serif" }}
                  >
                    We specialize in Data ETL, Visualization and Aggregation to
                    enable organizations to use their data to build insights.
                    Our analytical capabilities enable you to meet customer
                    needs faster with precision.
                  </p>
                </div>
                <LazyImage
                  src={web_content["ai_image.webp"]}
                  alt="Illustration of AI concepts"
                />
              </div>
            </div>
            <div
              className=" border-2 transparent hover:border-indigo-600 p-4 rounded-xl"
              role="listitem"
              aria-hidden="true"
              onMouseEnter={(e) => {
                e.currentTarget
                  .querySelector("h3")
                  .classList.add("text-indigo-600");
              }}
              onMouseLeave={(e) => {
                e.currentTarget
                  .querySelector("h3")
                  .classList.remove("text-indigo-600");
              }}
            >
              <div
                className="flex h-full flex-col justify-between"
                aria-labelledby="feature-analytics-heading"
              >
                <LazyImage
                  src={web_content["Prototyping_process.svg"]}
                  width="192"
                  alt="Rapid Prototyping"
                  className=" rounded-lg aspect-w-16 aspect-h-9"
                />
                <div>
                  <div className="flex items-center gap-2">
                    <Icons.Workflow className="h-5 w-6" aria-hidden="true" />
                    <h3
                      className="text-lg font-medium text-gray-950 hover:text-indigo-600 cursor-pointer"
                      id="feature-analytics-heading"
                    >
                      Rapid Prototyping
                    </h3>
                  </div>
                  <p
                    className="mt-2 text-start max-w-lg text-sm text-gray-600 max-lg:text-center  font-medium"
                    style={{ fontFamily: "Josefin Sans, sans-serif" }}
                  >
                    Presenting choices because one size does not fit all.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="shadow-md border-2 transparent hover:border-indigo-600 lg:row-span-2 p-4 rounded-xl"
              role="listitem"
              onMouseEnter={(e) => {
                e.currentTarget
                  .querySelector("h3")
                  .classList.add("text-indigo-600");
              }}
              onMouseLeave={(e) => {
                e.currentTarget
                  .querySelector("h3")
                  .classList.remove("text-indigo-600");
              }}
            >
              <div
                className="flex h-full flex-col justify-between"
                aria-labelledby="feature-analytics-heading"
              >
                <LazyImage
                  src={web_content["process_automation.webp"]}
                  alt="process_automation"
                />
                <div>
                  <div className="flex items-center gap-2">
                    <Icons.Workflow className="h-5 w-6" aria-hidden="true" />
                    <h3
                      className="text-lg font-medium text-gray-950 hover:text-indigo-600 cursor-pointer"
                      id="feature-analytics-heading"
                    >
                      Process Automation
                    </h3>
                  </div>
                  <p
                    className="mt-2 text-start max-w-lg text-sm text-gray-600 max-lg:text-center  font-medium"
                    style={{ fontFamily: "Josefin Sans, sans-serif" }}
                  >
                    We develop end to end robust automation to replace human
                    intensive operations. With high performance automation, we
                    make organizations more agile and competitive.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="border-2 transparent hover:border-indigo-600 p-4 rounded-xl"
              role="listitem"
              aria-hidden="true"
              onMouseEnter={(e) => {
                e.currentTarget
                  .querySelector("h3")
                  .classList.add("text-indigo-600");
              }}
              onMouseLeave={(e) => {
                e.currentTarget
                  .querySelector("h3")
                  .classList.remove("text-indigo-600");
              }}
            >
              <div
                className="flex flex-col justify-between"
                aria-labelledby="feature-analytics-heading"
              >
                <div>
                  <div className="flex items-center gap-2">
                    <Icons.Workflow className="h-5 w-6" aria-hidden="true" />
                    <h3
                      className="text-lg font-medium text-gray-950 hover:text-indigo-600 cursor-pointer"
                      id="feature-analytics-heading"
                    >
                      Scalable Development
                    </h3>
                  </div>
                  <p
                    className="mt-2 text-start max-w-lg text-sm text-gray-600 max-lg:text-center  font-medium"
                    style={{ fontFamily: "Josefin Sans, sans-serif" }}
                  >
                    Our systems scale with your needs to help you grow.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="border-2 transparent hover:border-indigo-600 p-4 rounded-xl"
              role="listitem"
              onMouseEnter={(e) => {
                e.currentTarget
                  .querySelector("h3")
                  .classList.add("text-indigo-600");
              }}
              onMouseLeave={(e) => {
                e.currentTarget
                  .querySelector("h3")
                  .classList.remove("text-indigo-600");
              }}
            >
              <div
                className="flex flex-col justify-between"
                aria-labelledby="feature-analytics-heading"
              >
                <LazyImage
                  src={web_content["Prototyping_process.svg"]}
                  width="152"
                  alt="Customer Centric Design"
                  className=" rounded-lg aspect-w-16 aspect-h-9"
                />
                <div>
                  <div className="flex items-center gap-2">
                    <Icons.Workflow className="h-5 w-6" aria-hidden="true" />
                    <h3
                      className="text-lg font-medium text-gray-950 hover:text-indigo-600 cursor-pointer"
                      id="feature-analytics-heading"
                    >
                      Customer Centric Design
                    </h3>
                  </div>
                  <p
                    className="mt-2 text-start max-w-lg text-sm text-gray-600 max-lg:text-center  font-medium"
                    style={{ fontFamily: "Josefin Sans, sans-serif" }}
                  >
                    Your needs and desires are at the center of our design.
                  </p>
                </div>
              </div>
            </div>
            <div
              className=" border-2 transparent hover:border-indigo-600 lg:col-span-2 p-4 rounded-xl"
              role="listitem"
              aria-hidden="true"
              onMouseEnter={(e) => {
                e.currentTarget
                  .querySelector("h3")
                  .classList.add("text-indigo-600");
              }}
              onMouseLeave={(e) => {
                e.currentTarget
                  .querySelector("h3")
                  .classList.remove("text-indigo-600");
              }}
            >
              <div
                className="flex h-full flex-col lg:flex-row items-center justify-between"
                aria-labelledby="feature-analytics-heading"
              >
                <LazyImage
                  src={web_content["ai_image.webp"]}
                  alt="Illustration of AI concepts"
                  width="192"
                  className=" rounded-lg aspect-w-16 aspect-h-9"
                />
                <div className="flex flex-col lg:items-start lg:justify-center">
                  <div className="flex items-center gap-2">
                    <Icons.Workflow className="h-5 w-6" aria-hidden="true" />
                    <h3
                      className="text-lg font-medium text-gray-950 hover:text-indigo-600 cursor-pointer"
                      id="feature-analytics-heading"
                    >
                      IoT Solutions
                    </h3>
                  </div>
                  <p
                    className="mt-2 text-start max-w-lg text-sm text-gray-600 max-lg:text-center  font-medium"
                    style={{ fontFamily: "Josefin Sans, sans-serif" }}
                  >
                    Our Remote sensing and Embedded Systems prowess will help
                    you build and deploy real time solutions to distributed
                    issues and solve them efficiently
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const ClientSection = () => {
  return (
    <section className="relative isolate flex items-center mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="text-center max-w-xl mx-auto">
          <h3 className="mx-auto mt-2 max-w-lg text-center text-4xl font-semibold tracking-tight text-balance text-gray-950 sm:text-5xl capitalize">
            What Clients Say
          </h3>
          <div className="text-center mb-10">
            <span className="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
            <span className="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
            <span className="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
            <span className="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
            <span className="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
          </div>
        </div>
        <div className="-mx-3 md:flex items-start">
          <div className="px-3 md:w-1/3">
            <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
              <div className="w-full flex mb-4 items-center">
                <div className="flex-grow pl-3">
                  <h6
                    className="font-bold text-sm uppercase"
                    style={{ fontFamily: "Roboto" }}
                  >
                    RailMadad Analytics Client.
                  </h6>
                </div>
              </div>
              <div className="w-full">
                <p
                  className="text-sm leading-tight"
                  style={{ fontFamily: "Roboto" }}
                >
                  <span className="text-lg leading-none italic font-bold text-gray-400 mr-2">
                    "
                  </span>
                  Our expertise lies in extracting valuable insights from
                  RailMadad Data, enabling informed decisions and actionable
                  outcomes.
                  <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                    "
                  </span>
                </p>
              </div>
            </div>
            <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
              <div className="w-full flex mb-4 items-center">
                <div className="flex-grow pl-3">
                  <h6
                    className="font-bold text-sm uppercase "
                    style={{ fontFamily: "Roboto" }}
                  >
                    Automated Monitoring System Client.
                  </h6>
                </div>
              </div>
              <div className="w-full">
                <p
                  className="text-sm leading-tight"
                  style={{ fontFamily: "Roboto" }}
                >
                  <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
                    "
                  </span>
                  This integrated solution merges embedded systems and software
                  to real-time monitor solar panel efficiency.
                  <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                    "
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="px-3 md:w-1/3">
            <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
              <div className="w-full flex mb-4 items-start">
                <div className="flex-grow pl-3">
                  <h6 className="font-bold text-sm uppercase">Jay Prakash.</h6>
                </div>
              </div>
              <div className="w-full">
                <p className="text-sm leading-tight">
                  <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
                    "
                  </span>
                  Our integrated platform, comprising a website and mobile app,
                  streamlines employee performance record management.
                  <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                    "
                  </span>
                </p>
              </div>
            </div>
            <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
              <div className="w-full flex mb-4 items-center">
                <div className="flex-grow pl-3">
                  <h6 className="font-bold text-sm uppercase">
                    Sanchalak (Railops) Client.
                  </h6>
                </div>
              </div>
              <div className="w-full">
                <p className="text-sm leading-tight">
                  <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
                    "
                  </span>
                  Sanchalak has completely changed how I travel by train!
                  Real-time tracking and berth details make my journey
                  stress-free. Highly recommended for frequent travelers!"
                  <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                    "
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="px-3 md:w-1/3">
            <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
              <div className="w-full flex mb-4 items-center">
                <div className="flex-grow pl-3">
                  <h6 className="font-bold text-sm uppercase">
                    ACP Monitoring Client.
                  </h6>
                </div>
              </div>
              <div className="w-full">
                <p className="text-sm leading-tight">
                  <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
                    "
                  </span>
                  An IoT-driven railway enhancement system records and tallies
                  Alarm Chain Pulling (ACP) instances, capturing GPS location,
                  trigger time, and resultant train delay.
                  <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                    "
                  </span>
                </p>
              </div>
            </div>
            <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
              <div className="w-full flex mb-4 items-center">
                <div className="flex-grow pl-3">
                  <h6 className="font-bold text-sm uppercase">
                    AWMS - Automated Water Monitoring System Client.
                  </h6>
                </div>
              </div>
              <div className="w-full">
                <p className="text-sm leading-tight">
                  <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
                    "
                  </span>
                  Revolutionizing train water management, our IoT solution
                  provides real-time updates on water tank storage to upcoming
                  stations.
                  <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                    "
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const StatsSection = () => {
  return (
    <section
      className="mb-[3rem] py-10 relative isolate flex items-center mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
      style={{ marginTop: "3rem", marginBottom: "3rem" }}
    >
      <div className="w-full px-2 sm:px-4 lg:px-6">
        <div className="text-center flex flex-col flex-1 gap-10 lg:gap-0 lg:flex-row lg:justify-between gap-4">
          <div className="w-full lg:w-1/4 border-b pb-10 lg:border-b-0 lg:pb-0 lg:border-r border-gray-100 cursor-pointer ">
            <div className="font-manrope font-bold text-5xl text-gray-900 mb-2 text-center ">
              405+
            </div>
            <span
              className="text-sm lowercase font-light text-center "
              style={{ fontSize: "0.875rem" }}
            >
              400 Trains, 5 Stations,
              <br /> Colonies: WIP, Hospital: WIP
            </span>
            <span className="text-xl text-gray-500 text-center block ">
              Booking Order
            </span>
          </div>
          <div className="w-full lg:w-1/4 border-b pb-10 lg:border-b-0 lg:pb-0 lg:border-r border-gray-100 cursor-pointer">
            <div className="font-manrope font-bold text-5xl text-gray-900 mb-4 text-center ">
              295+
            </div>
            <span
              className="text-sm font-light lowercase"
              style={{ fontSize: "0.875rem" }}
            >
              200 Trains, 90 Stations, Colonies: 5
            </span>
            <span className="text-xl text-gray-500 text-center block ">
              Booking Completed
            </span>
          </div>
          <div className="w-full lg:w-1/4 border-b pb-10 lg:border-b-0 lg:pb-0 lg:border-r border-gray-100 cursor-pointer">
            <div className="font-manrope font-bold text-5xl text-gray-900 mb-4 text-center ">
              3
            </div>
            <span className="text-xl text-gray-500 text-center block ">
              Available States
            </span>
          </div>
          <div className="w-full lg:w-1/4  cursor-pointer">
            <div className="font-manrope font-bold text-5xl text-gray-900 mb-4 text-center ">
              2
            </div>
            <span className="text-xl text-gray-500 text-center block ">
              Award Winner
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

const OurPartners = () => {
  const { web_content } = getAllImages;
  return (
    <section className="relative border-t border-b border-gray-100 isolate flex items-center justify-center mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div className="mx-auto max-w-7xl px-6 py-[20px] lg:px-8">
        <h2 className="mt-2 text-center text-4xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-5xl">
          Our Supporters
        </h2>
        <div className="relative my-4 gap-6 flex flex-wrap flex-row  items-center justify-center bg-clip-border rounded-xl text-gray-700  rounded-xl text-gray-700 ">
          <div className="shadow-md p-6 w-[200px] flex-col flex items-center justify-center">
            <LazyImage
              src={web_content["Incubation-Center-IIT-Patna.png"]}
              alt="indainR2logo"
              width="158"
              height="158"
              className="bg-transparent mix-blend-color aspect-square"
            />
          </div>
          <div className="shadow-md p-6 w-[200px] flex flex-col items-center justify-center">
            <LazyImage
              src={web_content["supporter1.jpeg"]}
              alt="supporter@startup_bihar"
              width="158"
              height="158"
              className="bg-transparent mix-blend-color aspect-square"
            />
          </div>
          <div className="shadow-md p-6 w-[200px] flex flex-col test-center items-center justify-center aspect-square">
            <LazyImage
              src={web_content["supporter3.jpeg"]}
              alt="Ministry of Electronics and Information Technology"
              width="158"
              height="158"
              className="bg-transparent mix-blend-color aspect-square"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const WhoWeAreSection = () => {
  const { web_content } = getAllImages;
  return (
    <section className="relative isolate flex items-center justify-center mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>
      <div className="overflow-hidden">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-0 md:gap-x-0 lg:mx-0 lg:max-w-none lg:grid-cols-2 mb-4">
            <div className="lg:max-w-lg">
              <h1 className="mt-2 text-4xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-5xl">
                Who We Are
              </h1>
              <p
                className="mt-6 text-xl/8 text-gray-700"
                style={{ fontFamily: "Josefin Sans, sans-serif" }}
              >
                Sarva Suvidhaen is a recognized leader in developing automation
                processes for complex organizations and institutional concerns.
                Our mission is to bring automation, technology, and innovation
                to simplify complex problems. We add value by increasing
                efficiency and driving data analytics for higher quality
                standards.
              </p>
              <p
                className="mt-6 text-xl/8 text-gray-700"
                style={{ fontFamily: "Josefin Sans, sans-serif" }}
              >
                Since our inception, we have collaborated with large
                organizations to deploy intelligent automation, resulting in
                improved turnaround times, enhanced service quality, and
                increased customer satisfaction.
              </p>
            </div>
            <div className="grid grid-cols-2 bg-none gap-4 mt-8 lg:row-span-2">
              <LazyImage
                className="mt-4 w-full lg:mt-10 rounded-lg "
                src={web_content["office-long-2.png"]}
                alt="office content 2"
              />
              <LazyImage
                className="mb-4 w-full lg:mt-10 rounded-lg"
                src={web_content["office-long-1.png"]}
                alt="office content 1"
              />
            </div>
            <div className="max-w-xl text-xl/8 text-gray-700">
              <p
                className="mt-5 lg:mt-0 text-xl/8 text-gray-700"
                style={{ fontFamily: "Josefin Sans, sans-serif" }}
              >
                Our team of solution architects, designers, and developers will
                help you craft a solution tailored to your requirements.
              </p>
              <p
                className="mt-3 text-xl/8 text-gray-700"
                style={{ fontFamily: "Josefin Sans, sans-serif" }}
              >
                We are a recognized DIPP Startup from Bihar and are proud
                recipients of multiple awards.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
