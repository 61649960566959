import React from "react";
import LazyImage from "../hooks/LazyImage";
import getAllImages from "../static/imageUtils";
import ScreenMockup from "../Components/Mockup";
import AppCarouselSlider from "../Components/AppSlider";
const SanchalakPage = () => {
  const { web_content, railops } = getAllImages;
  const appData = {
    Avatar: (
      <LazyImage
        as="span"
        src={web_content["railops(logo).webp"]}
        alt="Sanchalak"
        className="rounded-md border-1 flex-shrink-0"
      />
    ),
    AppInfo: {
      appname: "Sanchalak (Railops)",
      tagline: "Digital Monitoring tool of Station Cleanliness.",
      appurl:
        "https://play.google.com/store/apps/details?id=com.biputri.railops",
    },
  };

  return (
    <>
      <section className="relative shadow-[inset_0_-20px_-0_-0px_rgba(0,0,0,0.4)_blur] border-b-2 border-gray-100 isolate flex justify-between mx-auto max-w-7xl h-svh px-4 sm:px-6 lg:px-8 overflow-hidden bg-gray-50">
        <div className="absolute left-0 top-0 bottom-0 -z-10 w-full">
          <div className="absolute inset-0 h-full w-full bg-hero-background bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:40px_40px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"></div>
        </div>
        <div className="z-99 mx-auto max-w-2xl py-5">
          <div className="text-center">
            <h1
              className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold tracking-tight text-gray-900"
              aria-level="1"
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              Sanchalak/RailOps
            </h1>

            <p
              className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl font-medium text-gray-500 font-bold underline decoration-wavy decoration-indigo-600 "
              role="article"
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              Your Comprehensive Train Travel App
            </p>
            <p
              className="mt-4 text-foreground max-w-lg mx-auto text-gray-500"
              role="article"
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              Sanchalak is designed and developed to enhance and streamline your
              train travel experience. Simplify your train journeys with
              real-time tracking, detailed passenger and berth information, and
              easy profile management. Enjoy a seamless travel experience with
              our user-friendly app.!
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 relative">
              <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:gap-x-6 absolute top-0">
                <ScreenMockup>
                  <LazyImage
                    as="div"
                    lowSrc={railops["Screenshot_20250320_140105.jpg"]}
                    src={railops["Screenshot_20250320_140105.jpg"]}
                    alt="Sanchalak (Railops)"
                    className="rounded-lg shadow-md max-w-[275px]"
                    minHeight="200px"
                    width="100%"
                    height="auto"
                  />
                </ScreenMockup>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="relative isolate flex h-screen items-center justify-between mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/XuI5QqGvgtU?si=0NKe-ZONmBZaFI08"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </div> */}

      <section className="relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>

        <div className="mt-6 max-w-6xl mx-auto flex flex-col gap-[1rem] mt-10 mb-2 text-justify">
          <h1 className="mt-4 text-start mb-4 text-3xl leading-tight font-bold underline decoration-indigo-600 decoration-wavy">
            Benefits of Sanchalak
          </h1>
          <ul className="space-y-2 text-base leading-relaxed font-light md:text-sm lg:max-w-3xl lg:text-base text-start">
            <li>
              <span className="font-bold ">Convenience:</span> Manage your
              travel details and preferences in one place.
            </li>
            <li>
              <span className="font-bold ">Efficiency:</span> Ensures Quick
              access to essential information and options to save or share them.
            </li>
            <li>
              <span className="font-bold ">User-Friendly:</span> Simple
              navigation and intuitive design make it easy to use for all age
              groups
            </li>
          </ul>

          <h2 className="mt-6 mb-6 text-start text-4xl leading-tight font-bold underline decoration-indigo-600 decoration-wavy">
            App Interface and Features
          </h2>
        </div>
      </section>

      <section className="relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="col-span-2 row-span-2 h-full mb-4 text-justify flex flex-col justify-center">
            <div className="w-full">
              <p className="text-base/7 font-semibold text-indigo-600">
                Sanchalak (Railops)
              </p>
              <h1 className="mt-1 text-start text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                Getting Started
              </h1>
              <div className="text-base/7 text-gray-700 text-start ">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold">Download the App:</span>{" "}
                    Available on major app stores.
                  </li>
                  <li>
                    <span className="font-bold">Create an Account:</span>{" "}
                    Register with your details or use the Google sign-in option
                    for quick access.
                  </li>
                  <li>
                    <span className="font-bold">Login:</span> Use your
                    credentials to access the app.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row-span-2">
            <ScreenMockup>
              <LazyImage
                as="div"
                lowSrc={railops["Screenshot_20250320_143818.jpg"]}
                src={railops["Screenshot_20250320_143818.jpg"]}
                alt="Sanchalak (Railops)"
                className="rounded-lg shadow-md max-w-[275px]"
                minHeight="200px"
                width="100%"
                height="auto"
              />
            </ScreenMockup>
          </div>
        </div>
      </section>

      <section className="mt-10 relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="row-span-2 order-1 mt-5">
            <ScreenMockup>
              <LazyImage
                as="div"
                lowSrc={railops["Image 2025-03-20 at 3.33.35 PM.jpeg"]}
                src={railops["Image 2025-03-20 at 3.33.35 PM.jpeg"]}
                alt="Sanchalak (Railops)"
                className="rounded-lg shadow-md max-w-[275px]"
                minHeight="200px"
                width="100%"
                height="auto"
              />
            </ScreenMockup>
          </div>
          <div className="lg:order-last col-span-2 row-span-2 text-justify flex flex-col justify-center lg:ml-10 h-full">
            <div className="w-full">
              <p className="mt-3 text-start text-xl/8 text-gray-700 uppercase underline font-bold">
                Access to Coatch Attendant
              </p>
              <div className="text-base/7 text-gray-700 text-start">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold ">
                      Passenger and Berth Information:
                    </span>{" "}
                    View detailed information about passengers and berths for
                    selected trains.
                  </li>
                  <li>
                    <span className="font-bold">Attendance:</span> mark
                    attendance for your self and other staff members
                  </li>
                  <li>
                    <span className="font-bold">Charting & Location:</span> get
                    charting time for selected train number and user last
                    Locatoin
                  </li>
                </ul>
              </div>
              <div className="text-base/7 text-gray-700 text-start ">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold">Pnr Detail:</span> Coatch
                    Attendant can check for Pnr detail's of passanges.
                  </li>
                  <li>
                    <span className="font-bold">
                      Complaint & Issues Monitoring:
                    </span>{" "}
                    Monitor and report's train issues, can uppload image and
                    videos of train issues in assigned chatches
                  </li>
                  <li>
                    <span className="font-bold">Download/Email Reports:</span>{" "}
                    reports can be downloaded or emailed. by Coatch Attendant
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="col-span-2 row-span-2 mb-4 text-justify flex flex-col justify-center">
            <div className="w-full">
              <p className="mt-3 text-xl/8 text-gray-700 uppercase underline font-bold">
                Access to Railway Officer
              </p>
              <div className="text-base/7 text-gray-700 text-start ">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold">Assign EHK, CA & Obhs:</span>{" "}
                    Railway Officer can assign EHK, CA and OBBHS staff.
                  </li>
                  <li>
                    <span className="font-bold">Coatch Configratino:</span>{" "}
                    Railway Officer can do coatch configrations.
                  </li>
                  <li>
                    <span className="font-bold">User Management:</span> Railway
                    Officer can enable or disable users, can accept or deny
                    requested, add new users
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row-span-2">
            <ScreenMockup>
              <LazyImage
                as="div"
                lowSrc={railops["Screenshot_20250320_140253.jpg"]}
                src={railops["Screenshot_20250320_140253.jpg"]}
                alt="Sanchalak (Railops)"
                className="rounded-lg shadow-md max-w-[275px]"
                minHeight="200px"
                width="100%"
                height="auto"
              />
            </ScreenMockup>
          </div>
        </div>
      </section>

      <section className="mt-10 relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="row-span-2 order-1 mt-5">
            <ScreenMockup>
              <LazyImage
                as="div"
                lowSrc={railops["Screenshot_20250320_141126.jpg"]}
                src={railops["Screenshot_20250320_141126.jpg"]}
                alt="Sanchalak (Railops)"
                className="rounded-lg shadow-md max-w-[275px]"
                minHeight="200px"
                width="100%"
                height="auto"
              />
            </ScreenMockup>
          </div>
          <div className="lg:order-last col-span-2 row-span-2 text-justify flex flex-col justify-center lg:ml-10 h-full">
            <div className="w-full">
              <p className="mt-3 text-start text-xl/8 text-gray-700 uppercase underline font-bold">
                Profile Management
              </p>
              <div className="text-base/7 text-gray-700 text-start">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold ">Update Profile:</span> can
                    Manage user profile can updated user information.
                  </li>
                  <li>
                    <span className="font-bold">Chnage Email & password:</span>{" "}
                    Can able to change Email and & password also can chage user
                    Mobile number.
                  </li>
                  <li>
                    <span className="font-bold">Account deactivation:</span>
                    Can deactivate own account.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="pt-4"></div>
      <AppCarouselSlider
        {...appData}
        category={railops}
        images={getAllImages}
      />
    </>
  );
};

export default SanchalakPage;
