import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

const LazyImage = ({ as: Component = "div", lowSrc, src, alt, className, minHeight, width = "100%", height = "auto" }) => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const [loaded, setLoaded] = useState(false);

  return (
    <Component ref={ref} style={{ minHeight: minHeight }}>
      <img
        src={inView ? src : lowSrc}
        alt={alt}
        width={width}
        height={height}
        className={className}
        style={{
          filter: loaded ? "none" : "blur(10px)",
          transition: "filter 0.5s ease-in-out",
        }}
        onLoad={() => setLoaded(true)}
      />
    </Component>
  );
};

export default LazyImage; 