const founders = require.context(
  "./assets/founders",
  false,
  /\.(webp|png|jpg|jpeg|svg|gif|ico|bmp|tiff)$/
);
const railmadad = require.context(
  "./assets/railmadad",
  false,
  /\.(webp|png|jpg|jpeg|svg|gif|ico|bmp|tiff)$/
);
const sanchalak = require.context(
  "./assets/sanchalak",
  false,
  /\.(webp|png|jpg|jpeg|svg|gif|ico|bmp|tiff)$/
);
const swatchstation = require.context(
  "./assets/swatchstation",
  false,
  /\.(webp|png|jpg|jpeg|svg|gif|ico|bmp|tiff)$/
);
const teams = require.context(
  "./assets/teams",
  false,
  /\.(webp|png|jpg|jpeg|svg|gif|ico|bmp|tiff)$/
);
const web_content = require.context(
  "./assets/web_content",
  false,
  /\.(webp|png|jpg|jpeg|svg|gif|ico|bmp|tiff|mp4|webm)$/
);
const wrms = require.context(
  "./assets/wrms",
  false,
  /\.(webp|png|jpg|jpeg|svg|gif|ico|bmp|tiff)$/
);

const smScreen = require.context(
  "./assets/mockups/SM",
  false,
  /\.(webp|png|jpg|jpeg|svg|gif|ico|bmp|tiff)$/
);

const railops = require.context(
  "./assets/mockups/railops",
  false,
  /\.(webp|png|jpg|jpeg|svg|gif|ico|bmp|tiff)$/
);


const getImages = (context) => {
  const images = context.keys().reduce((acc, path) => {
    const key = path.replace("./", "");
    acc[key] = context(path);
    return acc;
  }, {});
  return images;
};


const getAllImages = {
  founders: getImages(founders),
  railmadad: getImages(railmadad),
  sanchalak: getImages(sanchalak),
  swatchstation: getImages(swatchstation),
  teams: getImages(teams),
  web_content: getImages(web_content),
  wrms: getImages(wrms),
  smScreen: getImages(smScreen),
  railops: getImages(railops),
};

export default getAllImages;
