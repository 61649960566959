import React from "react";
import getAllImages from "../static/imageUtils";
import Tobii from "@midzer/tobii";
import "@midzer/tobii/dist/tobii.min.css";
import "@midzer/tobii/dist/tobii.min.js";
import "../styles/App.css";
import LazyImage from "../hooks/LazyImage";


export default function PortfolioPage() {
  const { web_content } = getAllImages;
  const tobiiRef = React.useRef(null);

  React.useEffect(() => {
    const checkAndInitTobii = () => {
      const lightboxItems = document.querySelectorAll("[data-tobii]");
      if (lightboxItems.length > 0 && !tobiiRef.current) {
        tobiiRef.current = new Tobii({
          captions: false,
          counter: true,
          nav: "auto",
          zoom: true,
          draggable: true,
          keyboard: true,
          theme: "dark",
        });
      }
    };

    const timeout = setTimeout(checkAndInitTobii, 100);

    return () => {
      clearTimeout(timeout);
      if (tobiiRef.current) {
        tobiiRef.current.destroy();
        tobiiRef.current = null;
      }
    };
  }, []);

  return (
    <>
      <section className="relative isolate flex flex-col justify-between mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="z-99 mx-auto max-w-2xl py-5">
          <div className="text-center">
            <h1
              className="text-2xl  sm:text-3xl md:text-4xl lg:text-5xl font-semibold tracking-tight text-gray-900"
              aria-level="1"
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              Galary
            </h1>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div className="border border-gray-200">
            <a
              href={web_content["certificate_1.jpeg"]}
              data-tobii
              key={1}
              data-tobii-group="gallery"
              className="lightbox cursor-pointer"
            >
              <LazyImage
                as="span"
                className="w-full w-full object-fit"
                src={web_content["certificate_1.jpeg"]}
                alt="sertificate"
              />
            </a>
          </div>
          <div className="border border-gray-200">
          <a
              href={web_content["certificate_2.jpeg"]}
              data-tobii
              key={1}
              data-tobii-group="gallery"
              className="lightbox cursor-pointer"
            >
              <LazyImage
                as="span"
                className="w-full w-full object-fit"
                src={web_content["certificate_2.jpeg"]}
                alt="sertificate"
              />
            </a>
          </div>
          <div className="border border-gray-200">
          <a
              href={web_content["certificate_3.jpeg"]}
              data-tobii
              key={1}
              data-tobii-group="gallery"
              className="lightbox cursor-pointer"
            >
              <LazyImage
                as="span"
                className="w-full w-full object-fit"
                src={web_content["certificate_3.jpeg"]}
                alt="sertificate"
              />
            </a>
          </div>
          <div className="border border-gray-200">
          <a
              href={web_content["certificate_4.jpeg"]}
              data-tobii
              key={1}
              data-tobii-group="gallery"
              className="lightbox cursor-pointer"
            >
              <LazyImage
                as="span"
                className="w-full w-full object-fit"
                src={web_content["certificate_4.jpeg"]}
                alt="sertificate"
              />
            </a>
          </div>
          <div className="border border-gray-200">
          <a
              href={web_content["certificate_5.jpeg"]}
              data-tobii
              key={1}
              data-tobii-group="gallery"
              className="lightbox cursor-pointer"
            >
              <LazyImage
                as="span"
                className="w-full w-full object-fit"
                src={web_content["certificate_5.jpeg"]}
                alt="sertificate"
              />
            </a>
          </div>
          <div className="border border-gray-200">
          <a
              href={web_content["trophy1.jpg"]}
              data-tobii
              key={1}
              data-tobii-group="gallery"
              className="lightbox cursor-pointer"
            >
              <LazyImage
                as="span"
                className="w-full w-full object-fit"
                src={web_content["trophy1.jpg"]}
                alt="sertificate"
              />
            </a>
          </div>
          <div className="border border-gray-200">
          <a
              href={web_content["trophypdl.jpg"]}
              data-tobii
              key={1}
              data-tobii-group="gallery"
              className="lightbox cursor-pointer"
            >
              <LazyImage
                as="span"
                className="w-full w-full object-fit"
                src={web_content["trophypdl.jpg"]}
                alt="sertificate"
              />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
