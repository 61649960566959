import ScreenMockup from "../Components/Mockup";
import getAllImages from "../static/imageUtils";
import LazyImage from "../hooks/LazyImage";
import AppCarouselSlider from "../Components/AppSlider";

const { smScreen, web_content } = getAllImages;
const SwachhtaPage = () => {

  const appData = {
    Avatar: (
      <LazyImage
        as="span"
        src={web_content["SM(LOGO).webp"]}
        alt="Swachhta Management"
        width="100%"
        height="100%"
        className="w-full h-full rounded-md border-1 border-gray-100 flex-shrink-0"
      />
    ),
    AppInfo: {
      appname: "Swachhta Management",
      tagline: 'Digital Monitoring tool of Station Cleanliness.',
      appurl: "https://play.google.com/store/apps/details?id=swacchta.management.app"
    },
  };

  return (
    <>
      <section className="relative shadow-[inset_0_-20px_-0_-0px_rgba(0,0,0,0.4)_blur] border-b-2 border-gray-100 isolate flex justify-between mx-auto max-w-7xl h-svh px-4 sm:px-6 lg:px-8 overflow-hidden bg-gray-50">
        <div className="absolute left-0 top-0 bottom-0 -z-10 w-full">
          <div className="absolute inset-0 h-full w-full bg-hero-background bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:40px_40px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"></div>
        </div>
        <div className="z-99 mx-auto max-w-2xl py-5">
          <div className="text-center">
            <h1
              className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold tracking-tight text-gray-900"
              aria-level="1"
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              Swachhta Management
            </h1>

            <p
              className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl font-medium text-gray-500 font-bold underline decoration-wavy decoration-indigo-600 "
              role="article"
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              Digital Monitoring tool of Station Cleanliness
            </p>
            <p
              className="mt-4 text-foreground max-w-lg mx-auto text-gray-500"
              role="article"
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              Our integrated platform, available as a website and mobile app,
              simplifies employee performance management at Patna and Danapur
              Junction. It offers an efficient and user-friendly solution for
              tracking and assessing performance records.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 relative">
              <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:gap-x-6 absolute top-0">
                <ScreenMockup>
                  <LazyImage
                    as="div"
                    lowSrc={smScreen["homescreen.jpg"]}
                    src={smScreen["homescreen.jpg"]}
                    alt="Swachhta Management Image"
                    className="rounded-lg shadow-md max-w-[275px]"
                    minHeight="200px"
                    width="100%"
                    height="auto"
                  />
                </ScreenMockup>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="relative isolate flex h-screen items-center justify-between mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/XuI5QqGvgtU?si=0NKe-ZONmBZaFI08"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
      </div> */}
      
      <section className="relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>

        <div className="mt-6 max-w-6xl mx-auto flex flex-col gap-[1rem] mt-10 mb-2 text-justify">
          <h1 className="mb-4 text-3xl leading-tight font-bold underline decoration-indigo-600 decoration-wavy">
            Introduction
          </h1>
          <p className="text-base leading-relaxed font-light md:text-sm lg:max-w-3xl lg:text-base">
            The{" "}
            <span className="font-medium text-indigo-600">
              Swachhta Management{" "}
            </span>{" "}
            Tool is a digital solution introduced by the EnHM wing in the
            Danapur division for monitoring station cleanliness. It replaces the
            traditional manual contract monitoring system, providing a more
            efficient and transparent way to ensure cleanliness at railway
            stations.
          </p>

          <h2 className="mt-4 mb-4 text-3xl leading-tight font-bold underline decoration-indigo-600 decoration-wavy">
            Objective
          </h2>
          <p className="text-base leading-relaxed font-light md:text-sm lg:max-w-3xl lg:text-base">
            The primary goal of the{" "}
            <span className="font-medium text-indigo-600">
              Swachhta Management{" "}
            </span>{" "}
            Tool is to facilitate the monitoring of scheduled cleaning
            activities through a mobile application. The app, compatible with
            both Android and iOS platforms, allows Railway authorities to
            maintain records of daily, weekly, and monthly scores, penalties,
            passenger feedback, and geotagged photographs.
          </p>

          <h2 className="mt-4 mb-4 text-3xl leading-tight font-bold underline decoration-indigo-600 decoration-wavy">
            Advantages
          </h2>
          <ul className="space-y-2 text-base leading-relaxed font-light md:text-sm lg:max-w-3xl lg:text-base text-start">
            <li>
              <span className="font-bold ">Real-Time Remote Monitoring:</span>{" "}
              Allows officials to oversee station cleanliness remotely.
            </li>
            <li>
              <span className="font-bold ">Quality Assurance:</span> Ensures
              quality through geotagged photos.
            </li>
            <li>
              <span className="font-bold ">Paperless System:</span> Reduces
              paper usage significantly.
            </li>
            <li>
              <span className="font-bold ">Accessible Documentation:</span>{" "}
              Documentation can be accessed anytime, anywhere.
            </li>
            <li>
              <span className="font-bold ">Easy Access to Reports:</span>{" "}
              Previous reports are readily available.
            </li>
            <li>
              <span className="font-bold">Reduction of Manual Data Entry:</span>{" "}
              Eliminates the need for manual logbook entries.
            </li>
            <li>
              <span className="font-bold ">
                Increased Operational Efficiencies:
              </span>{" "}
              Faster data access and analysis with built-in graphs.
            </li>
          </ul>

          <h2 className="mt-6 mb-6 text-start text-4xl leading-tight font-bold underline decoration-indigo-600 decoration-wavy">
            App Interface and Features
          </h2>
        </div>
      </section>

      <section className="relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="col-span-2 mb-4 text-justify">
            <div className="w-full">
              <p className="text-base/7 font-semibold text-indigo-600">
                Swachhta Management
              </p>
              <h1 className="mt-1 text-start text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                for better workflow
              </h1>
              <p className="mt-3 text-xl/8 text-gray-700 uppercase underline font-bold">
                Access to CHI
              </p>
              <div className="text-base/7 text-gray-700 text-start ">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold">
                      Upload Images & Add Star Ratings:
                    </span>{" "}
                    Mandatory images for ratings, with comments for ratings of
                    0-2.
                  </li>
                  <li>
                    <span className="font-bold">
                      Non-Daily Task Configurations:
                    </span>{" "}
                    Allows configuration of tasks scheduled less frequently.
                  </li>
                  <li>
                    <span className="font-bold">Passenger Feedback:</span>{" "}
                    Collects feedback with digital signatures via email OTP.
                  </li>
                  <li>
                    <span className="font-bold">Inspection Feedback:</span>{" "}
                    Entered by CHI on behalf of inspecting officers.
                  </li>
                </ul>
              </div>
            </div>
            <div className="">
              <div className=" text-base/7 text-gray-700 ">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold">
                      Digital Signatures by CHI:
                    </span>{" "}
                    On daily, weekly, and monthly reports.
                  </li>
                  <li>
                    <span className="font-bold">Download/Email Reports:</span>{" "}
                    Various report types can be downloaded or emailed.
                  </li>
                  <li>
                    <span className="font-bold">
                      Monthly Reports for Billing:
                    </span>{" "}
                    Automatic calculation of deductions and penalties.
                  </li>
                  <li>
                    <span className="font-bold">Monitoring Stations:</span> CHI
                    at HQ monitors smaller stations.
                  </li>
                  <li>
                    <span className="font-bold">Leave Management:</span> CHI can
                    manage leave and additional charge requests.
                  </li>
                  <li>
                    <span className="font-bold">Station Transfers:</span> CHI
                    can request transfers, managed by the railway admin.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row-span-2">
            <ScreenMockup>
              <LazyImage
                as="div"
                lowSrc={smScreen["add rating form.jpg"]}
                src={smScreen["add rating form.jpg"]}
                alt="Swachhta Management Image"
                className="rounded-lg shadow-md max-w-[275px]"
                minHeight="200px"
                width="100%"
                height="auto"
              />
            </ScreenMockup>
          </div>
        </div>
      </section>

      <section className="mt-10 relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="row-span-2 order-1 mt-5">
            <ScreenMockup>
              <LazyImage
                as="div"
                lowSrc={smScreen["ratings details_.jpg"]}
                src={smScreen["ratings details_.jpg"]}
                alt="Swachhta Management Image"
                className="rounded-lg shadow-md max-w-[275px]"
                minHeight="200px"
                width="100%"
                height="auto"
              />
            </ScreenMockup>
          </div>
          <div className="lg:order-last col-span-2 row-span-2 text-justify flex flex-col justify-center lg:ml-10 h-full">
            <div className="w-full">
              <p className="mt-3 text-start text-xl/8 text-gray-700 uppercase underline font-bold">
                Access to Contractors
              </p>
              <div className="text-base/7 text-gray-700 text-start">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold ">
                      Upload Images &amp; Add Comments:
                    </span>{" "}
                    Contractors can upload images and add comments.
                  </li>
                  <li>
                    <span className="font-bold">
                      Digital Signatures by Contractor:
                    </span>{" "}
                    On daily and monthly reports.
                  </li>
                  <li>
                    <span className="font-bold">Download/Email Reports:</span>{" "}
                    Various report types can be downloaded or emailed.
                  </li>
                  <li>
                    <span className="font-bold">Inspection Feedback:</span>{" "}
                    Entered by CHI on behalf of inspecting officers.
                  </li>
                </ul>
              </div>
            </div>
            <div className=" text-base/7 text-gray-700 ">
              <ul role="list" className="pt-3 flex flex-col gap-3 text-start">
                <li>
                  <span className="font-bold">
                    Monthly Reports for Billing:
                  </span>{" "}
                  Automatic calculation of deductions and penalties.
                </li>
                <li>
                  <span className="font-bold">Additional Stations:</span>{" "}
                  Supervisors can work at multiple stations.
                </li>
                <li>
                  <span className="font-bold">Station Change Requests:</span>{" "}
                  Supervisors can request station changes.
                </li>
                <li>
                  <span className="font-bold">Transfers:</span> Contractors can
                  request transfers, managed by the railway admin.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="col-span-2 row-span-2 mb-4 text-justify flex flex-col justify-center">
            <div className="w-full">
              <p className="mt-3 text-xl/8 text-gray-700 uppercase underline font-bold">
                Access to Railway Officer
              </p>
              <div className="text-base/7 text-gray-700 text-start ">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold">Real-Time Monitoring:</span>{" "}
                    Monitor stations and view images and ratings.
                  </li>
                  <li>
                    <span className="font-bold">Complaint Monitoring:</span>{" "}
                    Monitor and get daily consolidated reports.
                  </li>
                  <li>
                    <span className="font-bold">Download/Email Reports:</span>{" "}
                    Various report types can be downloaded or emailed.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row-span-2">
            <ScreenMockup>
              <LazyImage
                as="div"
                lowSrc={smScreen["Ratings table_.jpg"]}
                src={smScreen["Ratings table_.jpg"]}
                alt="Swachhta Management Image"
                className="rounded-lg shadow-md max-w-[275px]"
                minHeight="200px"
                width="100%"
                height="auto"
              />
            </ScreenMockup>
          </div>
        </div>
      </section>

      <section className="mt-10 relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="row-span-2 order-1 mt-5">
            <ScreenMockup>
              <LazyImage
                as="div"
                lowSrc={smScreen["reports.jpg"]}
                src={smScreen["reports.jpg"]}
                alt="Swachhta Management Image"
                className="rounded-lg shadow-md max-w-[275px]"
                minHeight="200px"
                width="100%"
                height="auto"
              />
            </ScreenMockup>
          </div>
          <div className="lg:order-last col-span-2 row-span-2 text-justify flex flex-col justify-center lg:ml-10 h-full">
            <div className="w-full">
              <p className="mt-3 text-start text-xl/8 text-gray-700 uppercase underline font-bold">
                Access to Station Manager / Station Superintendent
              </p>
              <div className="text-base/7 text-gray-700 text-start">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold ">Logbook Access:</span> Fill
                    logbooks at stations without EnHM supervisors.
                  </li>
                  <li>
                    <span className="font-bold">
                      Download Complaint Reports:
                    </span>{" "}
                    Download reports with images.
                  </li>
                  <li>
                    <span className="font-bold">Raise Complaints:</span> Raise
                    complaints about station deficiencies.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 relative isolate z-50 mx-auto flex max-w-7xl justify-between px-12 text-center ">
        <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div>
        <div className="grid lg:grid-cols-[2fr_2fr_3fr] w-full lg:gap-4 grid-cols-1 lg:gap-4">
          <div className="col-span-2 row-span-2 mb-4 text-justify flex flex-col justify-center">
            <div className="w-full text-start">
              <p className="mt-3 text-xl/8 text-gray-700 uppercase underline font-bold">
                Access to Railway Administrator
              </p>
              <div className="text-base/7 text-gray-700 text-start ">
                <ul role="list" className="pt-2 flex flex-col gap-3 text-start">
                  <li>
                    <span className="font-bold">User Onboarding Requests:</span>{" "}
                    Accept or reject onboarding requests.
                  </li>
                  <li>
                    <span className="font-bold">Leave Requests:</span> Manage
                    leave requests.
                  </li>
                  <li>
                    <span className="font-bold">Transfer Requests:</span> Manage
                    transfer requests.
                  </li>
                  <li>
                    <span className="font-bold">Grant Leave Access:</span> Give
                    leave access for specific durations.
                  </li>
                  <li>
                    <span className="font-bold">Transfer Requests:</span> Give
                    transfer access.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row-span-2">
            <ScreenMockup>
              <LazyImage
                as="div"
                lowSrc={smScreen["reports.jpg"]}
                src={smScreen["reports.jpg"]}
                alt="Swachhta Management Image"
                className="rounded-lg shadow-md max-w-[275px]"
                minHeight="200px"
                width="100%"
                height="auto"
              />
            </ScreenMockup>
          </div>
        </div>
      </section>

      <AppCarouselSlider
        {...appData}
        category={smScreen}
        images={getAllImages}
      />


    </>
  );
};

export default SwachhtaPage;
